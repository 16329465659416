import React from 'react'
import Carousel from 'nuka-carousel'
import Img from 'gatsby-image'

export default class ImgCarousel extends React.Component {
  render() {
    return (
      <Carousel
        className="mobileOnly"
        wrapAround={true}
        initialSlideHeight={280}
        heightMode={'current'}
        autoplay={true}
        autoplayInterval={10000}
        defaultControlsConfig={{
          containerClassName: `mobileOnly`,
          pagingDotsStyle: {
            fill: 'white',
            transform: `scale(1.75)`,
            padding: `10px 10px`,
          },
        }}
        renderCenterLeftControls={({ previousSlide }) => null}
        renderCenterRightControls={({ nextSlide }) => null}
      >
        {this.props.handle === 'valta-atmosphere' && (
          <video
            muted
            loop
            poster={require(`~/images/${this.props.handle}-poster.png`)}
            autoPlay
            playsInline
            oncanplay="this.muted=true"
            style={{ width: `100%`, minHeight: `250px` }}
            key={0}
          >
            <source
              src={require(`~/images/${this.props.handle}.mp4`)}
              type="video/mp4"
            />
          </video>
        )}

        {this.props.handle === 'valta-aster' && (
          <Img fluid={this.props.asterImage} />
        )}

        {this.props.images.map((image, index) => (
          <Img fluid={image.localFile.childImageSharp.fluid} key={image.id} />
        ))}
      </Carousel>
    )
  }
}
