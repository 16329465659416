import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import SEO from '~/components/seo'
import ProductForm from '~/components/ProductForm'
import Layout from '~/layouts/layout'
import ImgCarousel from '~/components/Carousel'

const ProductPage = ({ data, pageContext }) => {
  const product = data.shopifyProduct
  const imagesCount = product.images.length
  const materials = data.mdx.exports.materials

  return (
    <Layout language={pageContext.lang} en={pageContext.en} nl={pageContext.nl}>
      <SEO
        title={product.title}
        description={product.description}
        metaImage={product.images[0].originalSrc}
      />

      {product.handle === 'valta-aster' && (
        <Img
          className="boxShadow grid7 desktopOnly"
          fluid={data.allFile.nodes[8].childImageSharp.fluid}
          style={{ gridRow: '1' }}
        />
      )}
      {product.handle === 'valta-atmosphere' && (
        <video
          className="boxShadow grid7 desktopOnly"
          muted
          loop
          poster={require(`~/images/${product.handle}-poster.png`)}
          autoPlay
          playsInline
          loading="lazy"
          oncanplay="this.muted=true"
          style={{ width: `100%`, gridRow: '1 / auto' }}
        >
          <source
            src={require(`~/images/${product.handle}.mp4`)}
            type="video/mp4"
          />
        </video>
      )}

      {product.images.map((image, index) => (
        <Img
          fluid={image.localFile.childImageSharp.fluid}
          key={image.id}
          alt={product.title}
          className="grid7 desktopOnly boxShadow"
          style={{ gridRow: index + 2 }}
        />
      ))}

      <ImgCarousel
        images={product.images}
        handle={product.handle}
        asterImage={data.allFile.nodes[8].childImageSharp.fluid}
      />
      <div
        className="grid5 productInfoContainer"
        style={{ gridRow: `1 / span ${imagesCount + 1}` }}
      >
        <div className="productInfoSticky boxShadow">
          <h1 className="productTitle">{product.title}</h1>

          <ProductForm
            product={product}
            mdx={data.mdx.body}
            language={pageContext.lang}
          />
        </div>
      </div>
      {product.handle === 'valta-aster' && (
        <>
          <div className="lifestyleGalleryContainer gridFullWidth">
            <Img
              fluid={data.allFile.nodes[0].childImageSharp.fluid}
              style={{ gridArea: 'a' }}
            />
            <Img
              fluid={data.allFile.nodes[5].childImageSharp.fluid}
              style={{ gridArea: 'b' }}
            />
            <Img
              fluid={data.allFile.nodes[6].childImageSharp.fluid}
              style={{ gridArea: 'c' }}
            />
            <Img
              fluid={data.allFile.nodes[7].childImageSharp.fluid}
              style={{ gridArea: 'd' }}
            />
            <Img
              fluid={data.allFile.nodes[8].childImageSharp.fluid}
              style={{ gridArea: 'e' }}
            />
          </div>
        </>
      )}

      {product.handle === 'valta-atmosphere' && (
        <>
          <div className="gridFullWidth socialProofContainer">
            {pageContext.lang == 'nl' && product.handle === 'valta-atmosphere' && (
              <>
                <h2 className="productTitle">Ervaringen</h2>
                <div className="socialProofCard boxShadow">
                  <img src={require('~/images/socialProof/sp-1.jpeg')} />
                  <p>Sara uit Gotenburg:</p>
                  <blockquote>
                    "De Valta Atmosphere past perfect bij mijn vintage kastje."
                  </blockquote>
                </div>
                <div className="socialProofCard boxShadow">
                  <img src={require('~/images/socialProof/sp-2.jpg')} />
                  <p>Allan uit Utrecht:</p>
                  <blockquote>
                    "Zo hoort een lamp er uit te zien. Minimalistisch.
                    Functioneel en gewoon mooi."
                  </blockquote>
                </div>
                <a
                  href="https://www.instagram.com/p/CId2pEjDdEd/"
                  className="socialProofCard boxShadow"
                >
                  <img src={require('~/images/socialProof/sp-3.jpg')} />
                  <p>@Groen_Stekje op Instagram:</p>
                  <blockquote>
                    "Dit lampje wordt gemaakt van plantaardige bronnen! Hoe
                    leuk, wij houden ervan:)"
                  </blockquote>
                </a>
              </>
            )}
            {pageContext.lang == 'en' && product.handle === 'valta-atmosphere' && (
              <>
                <h2 className="productTitle">What people say</h2>
                <div className="socialProofCard boxShadow">
                  <img src={require('~/images/socialProof/sp-1.jpeg')} />
                  <p>Sara from Göteborg:</p>
                  <blockquote>
                    "The Valta Atmosphere fits perfectly with my vintage side
                    table."
                  </blockquote>
                </div>
                <div className="socialProofCard boxShadow">
                  <img src={require('~/images/socialProof/sp-2.jpg')} />
                  <p>Allan from Utrecht:</p>
                  <blockquote>
                    "That's how a lamp should look like. Minimal. Functional and
                    aesthetically pleasing."
                  </blockquote>
                </div>
                <a
                  href="https://www.instagram.com/p/CId2pEjDdEd/"
                  className="socialProofCard boxShadow"
                >
                  <img src={require('~/images/socialProof/sp-3.jpg')} />
                  <p>@Groen_Stekje on Instagram:</p>
                  <blockquote>
                    "Dit lampje wordt gemaakt van plantaardige bronnen! Hoe
                    leuk, wij houden ervan:)"
                  </blockquote>
                </a>
              </>
            )}
          </div>
        </>
      )}

      <div
        className="grid5 productInfoSticky productInfoContainer"
        style={{ position: `initial` }}
      >
        <h2
          data-sal="slide-up"
          data-sal-duration="500"
          className="productTitle"
        >
          {pageContext.lang === 'nl' ? 'Materialen' : 'Materials'}
        </h2>
        <div className="productDescription">
          <p data-sal="slide-up" data-sal-duration="500">
            {materials.text}
          </p>
          <ul data-sal="slide-up" data-sal-duration="500">
            {materials.list.map((item, index) => {
              return (
                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
              )
            })}
          </ul>
        </div>
      </div>
      {/* <Img
        data-sal="fade"
        data-sal-duration="800"
        className="grid7 boxShadow"
        style={{ margin: `50px` }}
        fluid={data.allFile.nodes[1].childImageSharp.fluid}
      /> */}
      {/* <div className="gridFullWidth productQuestions">
        <h2 data-sal="slide-up">About the lamp</h2>
      </div> */}
      <div className="grid7" style={{ padding: `50px` }}>
        <video
          className="boxShadow"
          muted
          loop
          autoPlay
          playsInline
          loading="lazy"
          oncanplay="this.muted=true"
          style={{ width: `100%` }}
        >
          <source
            src={require(`~/images/${product.handle}.mp4`)}
            type="video/mp4"
          />
        </video>
      </div>
      {product.handle === 'valta-atmosphere' && (
        <>
          <Img
            data-sal="fade"
            data-sal-duration="800"
            className="grid4"
            fluid={data.allFile.nodes[1].childImageSharp.fluid}
          />
          <Img
            data-sal="fade"
            data-sal-duration="800"
            className="grid4"
            fluid={data.allFile.nodes[3].childImageSharp.fluid}
          />
          <Img
            data-sal="fade"
            data-sal-duration="800"
            className="grid4"
            fluid={data.allFile.nodes[4].childImageSharp.fluid}
          />
        </>
      )}
    </Layout>
  )
}

export const query = graphql`
  query($handle: String!, $lang: String!) {
    shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      options {
        id
        name
        values
      }
      variants {
        id
        title
        price
        availableForSale
        quantityAvailable
        shopifyId
        selectedOptions {
          name
          value
        }
      }
      priceRange {
        minVariantPrice {
          amount
          currencyCode
        }
        maxVariantPrice {
          amount
          currencyCode
        }
      }
      images {
        originalSrc
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: [
            "valta-atmosphere-1.jpg"
            "valta-atmosphere-2.jpg"
            "valta-atmosphere-3.jpg"
            "valta-atmosphere-4.jpg"
            "valta-aster-1.jpg"
            "valta-aster-2.jpg"
            "valta-aster-3.jpg"
            "valta-aster-4.jpg"
            "valta-aster-5.jpg"
          ]
        }
      }
    ) {
      nodes {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    mdx(frontmatter: { slug: { eq: $handle }, language: { eq: $lang } }) {
      frontmatter {
        slug
        title
        language
      }
      exports {
        materials {
          text
          list
        }
      }
      body
    }
  }
`

export default ProductPage
