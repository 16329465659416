import React, { useState, useContext, useEffect, useCallback } from 'react'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import StoreContext from '~/context/StoreContext'
import fbTrack from '~/components/fbTrack'
import HoverPopup from '../HoverPopup'

const ProductForm = ({ product, language, mdx }) => {
  const translations = {
    en: {
      addToCart: 'Add to Cart',
      outOfStock: 'Sorry, this item is currently out of stock',
      inStock: 'In stock',
      madeToOrder: 'Made to order',
      shipping: 'Ships the next business day',
      shippingMadeToOrder: 'Shipping within 1 week',
      shippingInfo:
        'We deliver your order to the carrier the next business day. This means if you order on a Wednesday, your order will be handed over to DHL on Thursday.',
      shippingInfoMTO:
        'We make this item specifically for you, so it takes a little longer. We will ship your order within 5 business days.',
      new: 'new',
    },
    nl: {
      addToCart: 'In mijn winkelwagen',
      outOfStock: 'Dit product is helaas momenteel uitverkocht',
      inStock: 'Op voorraad',
      madeToOrder: 'Op maat gemaakt',
      shipping: 'Verzending binnen 1 werkdag',
      shippingMadeToOrder: 'Verzending binnen 1 week',
      shippingInfo:
        'We leveren uw bestelling de eerstvolgende werkdag af bij de vervoerder. Dit betekent dat als u bestelt op woensdag, uw pakketje op donderdag bij DHL afgegeven wordt.',
      shippingInfoMTO:
        'We maken dit product speciaal voor u, daarom duurt het iets langer. We verzenden uw pakketje binnen 5 werkdagen na bestelling.',
      new: 'nieuw',
    },
  }
  const lang = translations[language]

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRange: { minVariantPrice },
  } = product
  const [variant, setVariant] = useState({ ...initialVariant })
  const [quantity, setQuantity] = useState(1)
  const {
    addVariantToCart,
    store: { client, adding },
  } = useContext(StoreContext)

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant
  const [available, setAvailable] = useState(productVariant.availableForSale)
  const [quantityAvailable, setQuantityAvailable] = useState(0)

  const checkAvailability = useCallback(
    productId => {
      // client.product.fetch(productId).then(fetchedProduct => {
      //   // this checks the currently selected variant for availability
      //   const result = fetchedProduct.variants.filter(variant => {
      //     return variant.id === productVariant.shopifyId
      //   })
      //   if (result.length > 0) {
      //     setAvailable(result[0].available)
      //   }
      // })

      const qtyQuery = client.graphQLClient.query(root => {
        root.add(
          'productByHandle',
          { args: { handle: `${product.handle}` } },
          productQ => {
            console.log(productQ)
            productQ.add('title')
            productQ.add('handle')
            productQ.add('id')
            productQ.addConnection(
              'variants',
              { args: { first: 10 } },
              variant => {
                variant.add('id')
                variant.add('availableForSale')
                variant.add('quantityAvailable')
              }
            )
          }
        )
      })
      return client.graphQLClient
        .send(qtyQuery)
        .then(res => {
          // console.log(res)
          // console.log(JSON.parse(JSON.stringify(res.model.productByHandle)))
          const result = JSON.parse(JSON.stringify(res.model.productByHandle))
          const filteredVariants = result.variants.filter(
            variant => variant.id === productVariant.shopifyId
          )
          setAvailable(filteredVariants[0].availableForSale)

          console.log(filteredVariants[0].availableForSale)
          console.log(filteredVariants[0].quantityAvailable)
          setQuantityAvailable(filteredVariants[0].quantityAvailable)

          // console.log(res)
          // return JSON.parse(JSON.stringify(res.model.product))
        })
        .catch(error => console.log(error))
    },
    [client.product, productVariant.shopifyId, variants]
  )

  useEffect(() => {
    checkAvailability(product.shopifyId)
  }, [productVariant, checkAvailability, product.shopifyId])

  const handleQuantityChange = ({ target }) => {
    setQuantity(target.value)
  }

  const handleOptionChange = (optionIndex, event) => {
    const { target } = event
    const value = target.getAttribute('value')
    console.log(variant)
    const currentOptions = [...variant.selectedOptions]

    currentOptions[optionIndex] = {
      ...currentOptions[optionIndex],
      value,
    }

    const selectedVariant = find(variants, ({ selectedOptions }) =>
      isEqual(currentOptions, selectedOptions)
    )

    setVariant({ ...selectedVariant })
  }

  const handleAddToCart = async () => {
    await addVariantToCart(productVariant.shopifyId, quantity)
    const cartUrl = language === 'en' ? '/cart/' : '/nl/winkelwagen'
    fbTrack('track', 'AddToCart')
    navigate(cartUrl)
  }

  /* 
  Using this in conjunction with a select input for variants 
  can cause a bug where the buy button is disabled, this 
  happens when only one variant is available and it's not the
  first one in the dropdown list. I didn't feel like putting 
  in time to fix this since its an edge case and most people
  wouldn't want to use dropdown styled selector anyways - 
  at least if the have a sense for good design lol.
  */
  const checkDisabled = (name, value) => {
    const match = find(variants, {
      selectedOptions: [
        {
          name: name,
          value: value,
        },
      ],
    })
    if (match === undefined) return true
    if (match.availableForSale === true) return false
    return true
  }

  const price = Intl.NumberFormat(undefined, {
    currency: minVariantPrice.currencyCode,
    minimumFractionDigits: 2,
    style: 'currency',
  }).format(variant.price)

  return (
    <>
      <h3 className="productPrice">
        {price}
        <span
          className="animatedGradient"
          style={{ textTransform: `uppercase` }}
        >
          {lang.new}
        </span>
      </h3>
      {/* <div
        className="productDescription"
        dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
      /> */}
      <div className="productDescription">
        <MDXRenderer>{mdx}</MDXRenderer>
      </div>

      {options.map(({ id, name, values }, index) => (
        <React.Fragment key={id}>
          {/* <label htmlFor={name}>{name} </label>
          <select
            name={name}
            key={id}
            onChange={event => handleOptionChange(index, event)}
          >
            {values.map(value => (
              <option
                value={value}
                key={`${name}-${value}`}
                disabled={checkDisabled(name, value)}
              >
                {value}
              </option>
            ))}
          </select> */}
          <div className="productOptions">
            <div>
              <p>
                {name}{' '}
                {/* <img src={require(`~/images/snoer.png`)} alt="Power Cord" />{' '} */}
                <span style={{ fontWeight: `normal`, opacity: 0.5 }}>
                  {variant.title}
                </span>
              </p>
            </div>
            <div className="colorOptions">
              {values.map(value => (
                <div
                  value={value}
                  key={`${name}-${value}`}
                  className={`colorSelectButton ${value} ${
                    variant.title === value ? 'activeVariant' : ''
                  }`}
                  // disabled={checkDisabled(name, value)}
                  onClick={event => handleOptionChange(index, event)}
                ></div>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
      {/* Not in stock and orders not allowed */}
      {!available && <p>{lang.outOfStock}.</p>}
      {/* In Stock */}
      {available && quantityAvailable > 0 && (
        <>
          <p className="inStock">
            <span className="stock">{lang.inStock}</span>
            <span className="shipping">
              {lang.shipping}
              <span>
                <img
                  src={require(`~/images/question.png`)}
                  alt="questionmark"
                />
                <HoverPopup text={lang.shippingInfo} />
              </span>
            </span>
          </p>
        </>
      )}
      {/* Made to Order (orders allowed, but out of stock) */}
      {available && quantityAvailable === 0 && (
        <>
          <p className="inStock">
            <span className="stock">{lang.madeToOrder}</span>
            <span className="shipping">
              {lang.shippingMadeToOrder}
              <span>
                <img
                  src={require(`~/images/question.png`)}
                  alt="questionmark"
                />
                <HoverPopup text={lang.shippingInfoMTO} />
              </span>
            </span>
          </p>
        </>
      )}

      {/* <label htmlFor="quantity">Quantity </label>
      <input
        type="number"
        id="quantity"
        name="quantity"
        min="1"
        step="1"
        onChange={handleQuantityChange}
        value={quantity}
      /> */}
      <button
        type="submit"
        disabled={!available || adding}
        onClick={handleAddToCart}
        className="addToCart"
      >
        {lang.addToCart} – {price}
      </button>
      {/* {!available && <p>{lang.outOfStock}.</p>} */}
    </>
  )
}

ProductForm.propTypes = {
  product: PropTypes.shape({
    descriptionHtml: PropTypes.string,
    handle: PropTypes.string,
    id: PropTypes.string,
    shopifyId: PropTypes.string,
    totalInventory: PropTypes.number,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        originalSrc: PropTypes.string,
      })
    ),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      })
    ),
    productType: PropTypes.string,
    title: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        quantityAvailable: PropTypes.number,
        availableForSale: PropTypes.bool,
        id: PropTypes.string,
        price: PropTypes.string,
        title: PropTypes.string,
        shopifyId: PropTypes.string,
        selectedOptions: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      })
    ),
  }),
  addVariantToCart: PropTypes.func,
}

export default ProductForm
